<template>
    <div class="modal fade" id="formModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{moduleName}}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer"> 
                    <button id="savebutton" type="button" class="btn btn-success" data-bs-dismiss="modal" @click.prevent="save">Opslaan</button> 
                </div>
            </div>
        </div>
    </div>
    <button class="d-none" data-bs-toggle="modal" data-bs-target="#formModal" id="formModalTrigger"></button>
</template>

<script>
    export default {
        name: 'CustomerFormPopup',
        emits: ['save', 'del'],
        data () {
			return {
				moduleName: '',
                values: []
			}
		},
        methods: {
            save() {
                this.$emit("save", this.values);
            },
            trigger(item) {
                this.moduleName = item.name;
                document.getElementById('formModalTrigger').click();
            }
        }
    }

</script>