import http from '@/http-common'

const URL = '/api/bookkeeping'
const URL_UNTILL = '/api/untill'
const URL_RESERVATION = '/api/reservation'

class IntegrationService{
    getUrl(){
        return URL
    }

    getAuthUrl(locationId,loginType) {
       return http.get(`${this.getUrl()}/${locationId}/get-login-url`)
    }
    processCallback(locationId, code) {
        return http.post(`${this.getUrl()}/callback`, {locationId: locationId, code: code})
    }
    getDivisions(locationId) {
        return http.get(`${this.getUrl()}/${locationId}/get-divisions`)
    }
    getCurrentDivision(locationId) {
        return http.get(`${this.getUrl()}/${locationId}/get-current-division`)
    }
    getBookkeepingGlAccounts(locationId) {
        return http.get(`${this.getUrl()}/${locationId}/glaccounts`)
    }
    coupleBookkeepingGlAccounts(locationId) {
        return http.get(`${this.getUrl()}/${locationId}/couple-glaccounts`)
    }
    getBookkeepingData(locationId) {
        return http.get(`${this.getUrl()}/${locationId}/all`)
    }
    getBookkeepingVatCodes(locationId) {
        return http.get(`${this.getUrl()}/${locationId}/vatcodes`)
    }
    export(locationId, dayStateId) {
        return http.get(`${this.getUrl()}/${locationId}/export/${dayStateId}`)
    }

    testConnection(locationId) {
        return http.get(`${URL_UNTILL}/${locationId}/status`)
    }
    getPaymentMethods(locationId) {
        return http.get(`${URL_UNTILL}/${locationId}/payment-methods`)
    }
    getTurnoverGroups(locationId) {
        return http.get(`${URL_UNTILL}/${locationId}/turnover-groups`)
    }
    getPosSalesAreas(locationId) {
        return http.get(`${URL_UNTILL}/${locationId}/sales-areas`)
    }
    getPosArticles(locationId) {
        return http.get(`${URL_UNTILL}/${locationId}/articles`)
    }

    getSuppliers(locationId) {
        return http.get(`${URL_UNTILL}/${locationId}/suppliers`)
    }

    testReservationConnection(locationId) {
        return http.get(`${URL_RESERVATION}/${locationId}/status`)
    }
    getReservationGroups(locationId) {
        return http.get(`${URL_RESERVATION}/${locationId}/groups`)
    }

    getClientConnections() {
        return http.get(`${URL_UNTILL}/client-connections`)
    }
}

export default new IntegrationService()
